<template>
  <div class="card">
    <div class="flex mg-b-16">
      <span class="label is-bold">Contact Info</span>
    </div>
    <div>
      <template  v-if="form.client_type==1">
        <span class="flex-sb-center mg-b-4">
          <span class="fs-14 fw-400">Policyholder:</span>
          <span class="fs-14 fw-500 text-end">{{form.policy_holder_name}}</span>
        </span>
        <span class="flex-sb-center">
          <span class="fs-14 fw-400">Mobile:</span>
          <span  class="flex flex-aligin">
            <span class="fs-14 fw-500">{{form.policy_holder_mobile}}</span>
            <a
              v-if="form.policy_holder_mobile"
              :href="`https://wa.me/852${form.policy_holder_mobile}`"
              target="_blank">
              <font-awesome-icon
                class="whats-app mg-l-10"
                :icon="['fab','whatsapp-square']"></font-awesome-icon>
            </a>
          </span>
        </span>
      </template>
      <template v-else>
        <span class="flex-sb mg-b-4">
          <span class="fs-14 fw-400">Policyholder:</span>
          <span class="fs-14 fw-500">{{form.holder_company_name}} Company</span>
        </span>
        <span class="flex-sb mg-b-4">
          <span class="fs-14 fw-400">Contact Personal:</span>
          <span class="fs-14 fw-500 text-end">{{form.holder_contact_name}}</span>
        </span>
        <span class="flex-sb-center">
          <span class="fs-14 fw-400">Mobile:</span>
          <span  class="flex flex-aligin text-end">
            <span  class="fs-14 fw-500">{{form.holder_contact_phone}}</span>
            <a
              v-if="form.holder_contact_phone"
              :href="`https://wa.me/852${form.holder_contact_phone}`"
              target="_blank">
              <font-awesome-icon
                class="whats-app mg-l-10"
                :icon="['fab','whatsapp-square']"></font-awesome-icon>
            </a>
          </span>
        </span>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'contact-info',
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    form: {
      type: Object,
      default: () => ({})
    },
  }
}
</script>

<style scoped lang="scss">
.card {
  background: white;
  border-radius: 5px;
  padding: 15px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.12);
  margin-top: 20px;
  .text-end{
    text-align: right;
  }
  .mg-l-10{
    margin-left: 10px;
  }
}
</style>
