<template>
  <v-dialog
    v-model="dialog"
    class="width-dialog-sm-and-down dialog-box"
    width="430"
  >
    <div class="dialog-container width-dialog-sm-and-down">
      <div class="dialog-header">
        <h2>Date of Policy Received</h2>
      </div>
      <div
        class="dialog-body"
      >
        <div
          class="transparent sent-content">
          <el-form
            ref="form"
            :model="formData"
            :rules="rules"
          >

            <div class="form-item">
              <span>Policy Number</span>
              <el-form-item prop="policy_no">
                <v-input
                  v-model="formData.policy_no"></v-input>
              </el-form-item>
            </div>
            <div class="form-item ">
              <span>Policy</span>
              <el-form-item prop="policy_document_file_id">
                <file-container
                  :edit="true"
                  :fileData="{
                    url: formData.policy_document_file_url,
                    client_name: formData.policy_document_client_name
                  }"
                  :otherData="{
                    idKey: 'policy_document_id',
                    urlKey: 'policy_document_file_url',
                    client_name:'policy_document_client_name'
                  }"
                  @delete="handlerDeleteFile"
                  @uploadSuccess="handlerUploadSuccess" />

                <!--              <v-upload v-model="formData.policy_document_file_id"></v-upload>-->
              </el-form-item>
            </div>
            <!--            <div-->
            <!--              v-if="showMotor"-->
            <!--              class="form-item ">-->
            <!--              <span>Certificate of Insurance </span>-->
            <!--              <el-form-item prop="policy_document_file_id">-->
            <!--                <file-container-->
            <!--                  :edit="true"-->
            <!--                  :fileData="{-->
            <!--                    url: formData.ci_file_url,-->
            <!--                    client_name: formData.ci_file_client_name-->
            <!--                  }"-->
            <!--                  :otherData="{-->
            <!--                    idKey: 'ci_file_id',-->
            <!--                    urlKey: 'ci_file_url',-->
            <!--                    client_name:'ci_file_client_name'-->
            <!--                  }"-->
            <!--                  @delete="handlerDeleteFile"-->
            <!--                  @uploadSuccess="handlerUploadSuccess" />-->
            <!--              </el-form-item>-->
            <!--            </div>-->
          </el-form>
        </div>

      </div>
      <div
        class="dialog-footer"
        align="right">
        <div class="btn-container">
          <v-button
            size="small"
            class="button-theme-block"
            @click="dialog = false">Cancel
          </v-button>
          <v-button
            size="small"
            type="primary"
            class="button-theme"
            @click="handlerSave">Save
          </v-button>
        </div>
      </div>
    </div>
  </v-dialog>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import MixinRules from '@/mixins/rules'
import MixinOptions from '@/mixins/options'
import {endorsementQuotationMotor} from '@api/endorsement'
import {saveQuotationMotor} from '@api/quotation'
export default {
  name: 'hirePurchaseDialog',
  mixins: [MixinOptions,MixinRules],
  data() {
    return {
      dialog:false,
      isSent:false,
      date:'',
      formData:{
        policy_no:'',
        policy_document_id: '',
        // policy_receive_date: '',
        policy_document_client_name:'',
        policy_document_file_url:''
      }
    }
  },
  computed:{
    ...mapState('quotation', ['quoteInformationForm']),
    showMotor(){
      return this.quoteInformationForm.product_id==10
    }
  },
  created() {

  },
  methods: {
    showReferDialog(){
      this.dialog = !this.dialog
    },
    // file-container 組件刪除文件
    handlerDeleteFile(otherData) {
      const { idKey, nameKey, urlKey,client_name } = otherData
      this.$set(this.formData, idKey, '')
      this.$set(this.formData, urlKey, '')
      this.$set(this.formData, nameKey, '')
      this.$set(this.formData, client_name, '')
    },
    handlerUploadSuccess({ data, result, otherData }) {
      const {  idKey,  urlKey,client_name } = otherData
      const { file_id,  url,path } = data
      this.$set(this.formData, idKey, file_id)
      this.$set(this.formData, urlKey, url)
      this.$set(this.formData, client_name, path)
      this.$refs['form'].clearValidate([idKey])
    },
    handlerSave(){
      // let {policy_status} = this.quoteInformationForm
      // let params =JSON.stringify(this.quoteInformationForm)
      // params = JSON.parse(params)
      // params = {...params,...this.formData}
      // if(policy_status===203)
      //   this.network().endorsementQuotationMotor(params)
      // else this.network().saveQuotation(params)
      this.$emit('update',this.formData)
      this.dialog =false
    },
    network() {
      return {
        saveQuotation: async (params) => {
          let { data } = await saveQuotationMotor({...params,is_edit:1})
          if(data){
            this.$emit('updateCoverage')
          }
        },
        endorsementQuotationMotor: async (params) => {
          let { data } = await endorsementQuotationMotor({...params,is_edit:1})
          if(data){
            this.$emit('updateCoverage')
          }
        },
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.dialog-box{
  width: 430px!important;
  ::v-deep .el-dialog{
    width: 430px;
  }
  .dialog-container {
    padding: 30px;
    color: #616266;
    width: 430px;
    .dialog-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      //margin: 0 -15px;
      //padding: 30px 30px 0 30px;
      //   border-bottom: 1px solid #dddfe7;
      h1 {
        font-size: 24px;
      }
    }
    .dialog-body{
      .sent-content{
        margin: 20px 0;
        color: #718096;
        .el-checkbox{
          margin-right: 10px;
        }
      }
      .form-item{
        margin: 0 0px 30px 0px;
        span{
          color: #718096;
        }
      }
    }

    .transparent{
      margin-bottom: 20px;
    }
  }
}
</style>
